import { Routes } from '@angular/router';
import { loggedInGuard } from './core/guards/logged-in/logged-in.guard';
import { loggedOutGuard } from './core/guards/logged-out/logged-out.guard';
import { BaseComponent } from './views/base/base.component';
import { ForbiddenComponent } from './views/errors/forbidden/forbidden.component';
import { PageNotFoundComponent } from './views/errors/page-not-found/page-not-found.component';
import { TopFiveBaseComponent } from './views/top-five/top-five-base/top-five-base.component';
import { BusinessAccountDetailsBaseComponent } from './views/business-accounts/business-account-details/business-account-details-base/business-account-details-base.component';
import { BusinessAccountDetailsServicesComponent } from './views/business-accounts/business-account-details/business-account-details-services/business-account-details-services.component';
import { BusinessAccountDetailsStoreComponent } from './views/business-accounts/business-account-details/business-account-details-store/business-account-details-store.component';

import { CustomerAccountDetailsBaseComponent } from './views/customer-management/customer-account-details/customer-account-details-base/customer-account-details-base.component';

export const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: '',
        title: 'Dashboard',
        loadComponent: () =>
          import('./views/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
      },

      {
        path: 'support',
        title: 'Support',
        loadComponent: () =>
          import('./views/support/support.component').then(
            (m) => m.SupportComponent
          ),
      },
      {
        path: 'industries',
        title: 'Industries',
        loadComponent: () =>
          import('./views/industries/industries.component').then(
            (m) => m.IndustriesComponent
          ),
      },
      {
        path: 'top-five',
        component: TopFiveBaseComponent,
        children: [
          {
            path: 'stores',
            title: 'Stores',
            loadComponent: () =>
              import(
                './views/top-five/top-five-stores/top-five-stores.component'
              ).then((m) => m.TopFiveStoresComponent),
          },
          {
            path: 'providers',
            title: 'Providers',
            loadComponent: () =>
              import(
                './views/top-five/top-five-providers/top-five-providers.component'
              ).then((m) => m.TopFiveProvidersComponent),
          },
          { path: '**', redirectTo: 'stores', pathMatch: 'full' },
        ],
      },
      {
        path: 'business-accounts',
        children: [
          {
            path: '',
            title: 'Business Accounts',
            loadComponent: () =>
              import(
                './views/business-accounts/all-business-accounts/all-business-accounts.component'
              ).then((m) => m.AllBusinessAccountsComponent),
          },
          {
            path: ':businessAccountID',
            component: BusinessAccountDetailsBaseComponent,
            children: [
              {
                path: 'overview',
                title: 'Overview',
                loadComponent: () =>
                  import(
                    './views/business-accounts/business-account-details/business-account-details-overview/business-account-details-overview.component'
                  ).then((m) => m.BusinessAccountDetailsOverviewComponent),
              },
              {
                path: 'posts',
                title: 'Posts',
                loadComponent: () =>
                  import(
                    './views/business-accounts/business-account-details/business-account-details-posts/business-account-details-posts.component'
                  ).then((m) => m.BusinessAccountDetailsPostsComponent),
              },
              {
                path: 'reels',
                title: 'Reels',
                loadComponent: () =>
                  import(
                    './views/business-accounts/business-account-details/business-account-details-reels/business-account-details-reels.component'
                  ).then((m) => m.BusinessAccountDetailsReelsComponent),
              },
              {
                path: 'store',
                component: BusinessAccountDetailsStoreComponent,
                children: [
                  {
                    path: 'orders',
                    loadComponent: () =>
                      import(
                        './views/business-accounts/business-account-details/business-account-details-store/business-account-details-store-orders/business-account-details-store-orders.component'
                      ).then(
                        (m) => m.BusinessAccountDetailsStoreOrdersComponent
                      ),
                  },
                  {
                    path: 'categories',
                    loadComponent: () =>
                      import(
                        './views/business-accounts/business-account-details/business-account-details-store/business-account-details-store-categories/business-account-details-store-categories.component'
                      ).then(
                        (m) => m.BusinessAccountDetailsStoreCategoriesComponent
                      ),
                  },
                  {
                    path: 'products',
                    loadComponent: () =>
                      import(
                        './views/business-accounts/business-account-details/business-account-details-store/business-account-details-store-products/business-account-details-store-products.component'
                      ).then(
                        (m) => m.BusinessAccountDetailsStoreProductsComponent
                      ),
                  },
                  {
                    path: '**',
                    redirectTo: 'orders',
                    pathMatch: 'full',
                  },
                ],
              },
              {
                path: 'services',
                component: BusinessAccountDetailsServicesComponent,
                children: [
                  {
                    path: 'provider-services',

                    loadComponent: () =>
                      import(
                        './views/business-accounts/business-account-details/business-account-details-services/business-account-details-services-description/business-account-details-services-description.component'
                      ).then(
                        (m) =>
                          m.BusinessAccountDetailsServicesDescriptionComponent
                      ),
                  },
                  {
                    path: 'categories',

                    loadComponent: () =>
                      import(
                        './views/business-accounts/business-account-details/business-account-details-services/business-account-details-services-categories/business-account-details-services-categories.component'
                      ).then(
                        (m) =>
                          m.BusinessAccountDetailsServicesCategoriesComponent
                      ),
                  },
                  {
                    path: 'appointments',
                    children: [
                      {
                        path: '',
                        loadComponent: () =>
                          import(
                            './views/business-accounts/business-account-details/business-account-details-services/business-account-details-services-appointments/business-account-details-services-appointments.component'
                          ).then(
                            (m) =>
                              m.BusinessAccountDetailsServicesAppointmentsComponent
                          ),
                      },

                      {
                        path: ':appointmentID',
                        title: 'Appointment Details',
                        loadComponent: () =>
                          import(
                            './views/business-accounts/business-account-details/business-account-details-services/business-account-details-services-appointments/business-account-details-services-appointments-details/business-account-details-services-appointments-details.component'
                          ).then(
                            (m) =>
                              m.BusinessAccountDetailsServicesAppointmentsDetailsComponent
                          ),
                      },
                    ],
                  },
                  {
                    path: '**',
                    redirectTo: 'provider-services',
                    pathMatch: 'full',
                  },
                ],
              },
              {
                path: 'commission',
                title: 'Commission',
                loadComponent: () =>
                  import(
                    './views/business-accounts/business-account-details/business-account-details-commission/business-account-details-commission.component'
                  ).then((m) => m.BusinessAccountDetailsCommissionComponent),
              },
              { path: '**', redirectTo: 'overview', pathMatch: 'full' },
            ],
          },
          { path: '**', redirectTo: '', pathMatch: 'full' },
        ],
      },
      {
        path: 'finance-management',
        children: [
          {
            path: '',
            title: 'Finance management',
            loadComponent: () =>
              import(
                './views/finance-management/finance-management.component'
              ).then((m) => m.FinanceManagementComponent),
          },
          {
            path: ':accountID',
            title: 'Account Details',
            loadComponent: () =>
              import(
                './views/finance-management/account-details/account-details.component'
              ).then((m) => m.AccountDetailsComponent),
          },
        ],
      },
      {
        path: 'customer-management',
        children: [
          {
            path: '',
            title: 'Customer Account',
            loadComponent: () =>
              import(
                './views/customer-management/customer-management.component'
              ).then((m) => m.CustomerManagementComponent),
          },
          {
            path: ':customerAccountID',
            component: CustomerAccountDetailsBaseComponent,
            children: [
              {
                path: 'overview',
                title: 'Overview',
                loadComponent: () =>
                  import(
                    './views/customer-management/customer-account-details/customer-account-details-overview/customer-account-details-overview.component'
                  ).then((m) => m.CustomerAccountDetailsOverviewComponent),
              },
              {
                path: 'orders',
                children: [
                  {
                    path: '',
                    title: 'All Orders',
                    loadComponent: () =>
                      import(
                        './views/customer-management/customer-account-details/customer-account-details-orders/customer-account-details-orders.component'
                      ).then((m) => m.CustomerAccountDetailsOrdersComponent),
                  },
                  {
                    path: ':orderID',
                    title: 'Order Details',
                    loadComponent: () =>
                      import(
                        './views/customer-management/customer-account-details/customer-account-details-orders/order-details/order-details.component'
                      ).then((m) => m.OrderDetailsComponent),
                  },
                ],
              },
              {
                path: 'appointments',
                children: [
                  {
                    path: '',
                    title: 'All Appointments',
                    loadComponent: () =>
                      import(
                        './views/customer-management/customer-account-details/customer-account-details-appointments/customer-account-details-appointments.component'
                      ).then(
                        (m) => m.CustomerAccountDetailsAppointmentsComponent
                      ),
                  },
                  {
                    path: ':appointmentID',
                    title: 'Appointment Details',
                    loadComponent: () =>
                      import(
                        './views/customer-management/customer-account-details/customer-account-details-appointments/appointment-details/appointment-details.component'
                      ).then((m) => m.AppointmentDetailsComponent),
                  },
                ],
              },

              {
                path: 'posts',
                title: 'Posts',
                loadComponent: () =>
                  import(
                    './views/customer-management/customer-account-details/customer-account-details-posts/customer-account-details-posts.component'
                  ).then((m) => m.CustomerAccountDetailsPostsComponent),
              },
              {
                path: 'reels',
                title: 'Reels',
                loadComponent: () =>
                  import(
                    './views/customer-management/customer-account-details/customer-account-details-reels/customer-account-details-reels.component'
                  ).then((m) => m.CustomerAccountDetailsReelsComponent),
              },
              { path: '**', redirectTo: 'overview', pathMatch: 'full' },
            ],
          },
          { path: '**', redirectTo: '', pathMatch: 'full' },
        ],
      },
      {
        path: 'ads',
        title: 'Ads',
        loadComponent: () =>
          import('./views/ads/all-ads/all-ads.component').then(
            (m) => m.AllAdsComponent
          ),
      },
      {
        path: 'mails',
        title: 'Mails',
        loadComponent: () =>
          import('./views/mails/all-mails/all-mails.component').then(
            (m) => m.AllMailsComponent
          ),
      },
      {
        path: 'notifications',
        title: 'Notifications',
        loadComponent: () =>
          import(
            './views/notifications/send-notifications/send-notifications.component'
          ).then((m) => m.SendNotificationsComponent),
      },
      {
        path: 'business-accounts-applications',
        title: 'B A A',
        loadComponent: () =>
          import(
            './views/business-accounts-applications/business-accounts-applications.component'
          ).then((m) => m.BusinessAccountsApplicationsComponent),
      },
      {
        path: 'content-creator-applications',
        title: 'C C A',
        loadComponent: () =>
          import(
            './views/content-creator-applications/content-creator-applications.component'
          ).then((m) => m.ContentCreatorApplicationsComponent),
      },
      { path: 'dashboard', redirectTo: '' },
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {
    path: 'auth',
    canActivate: [loggedOutGuard],
    children: [
      {
        path: 'login',
        title: 'Login',

        loadComponent: () =>
          import('./views/auth/login/login.component').then(
            (m) => m.LoginComponent
          ),
      },
      {
        path: 'forget-password',
        title: 'Forget Password',
        loadComponent: () =>
          import('./views/auth/forget-password/forget-password.component').then(
            (m) => m.ForgetPasswordComponent
          ),
      },
      {
        path: 'reset-password',
        title: 'Reset Password',
        loadComponent: () =>
          import('./views/auth/reset-password/reset-password.component').then(
            (m) => m.ResetPasswordComponent
          ),
      },
      {
        path: 'login-verification',
        title: 'Login Verification',
        loadComponent: () =>
          import(
            './views/auth/login-verification/login-verification.component'
          ).then((m) => m.LoginVerificationComponent),
      },
      {
        path: 'forget-password-verification',
        title: 'Forget Password Verification',
        loadComponent: () =>
          import(
            './views/auth/forget-password-verification/forget-password-verification.component'
          ).then((m) => m.ForgetPasswordVerificationComponent),
      },
      {
        path: 'login-with-otp',
        title: 'Login With OTP',
        loadComponent: () =>
          import('./views/auth/login-with-otp/login-with-otp.component').then(
            (m) => m.LoginWithOtpComponent
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
    ],
  },
  {
    path: 'errors',
    children: [
      {
        path: '404',
        title: 'Page Not Found',
        component: PageNotFoundComponent,
      },
      {
        path: '401',
        title: 'Unauthorized',
        component: ForbiddenComponent,
      },
    ],
  },

  { path: '**', redirectTo: 'error/404', pathMatch: 'full' },
];
